import { render, staticRenderFns } from "./iMouVideo.vue?vue&type=template&id=56f7c59a&scoped=true"
import script from "./iMouVideo.vue?vue&type=script&lang=js"
export * from "./iMouVideo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56f7c59a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\bak\\banling-web-front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('56f7c59a')) {
      api.createRecord('56f7c59a', component.options)
    } else {
      api.reload('56f7c59a', component.options)
    }
    module.hot.accept("./iMouVideo.vue?vue&type=template&id=56f7c59a&scoped=true", function () {
      api.rerender('56f7c59a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ImouVideo/iMouVideo.vue"
export default component.exports